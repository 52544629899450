<template>
    <div
        ref="slider"
        class="image-slider"
        :class="{ 'auto-height': !hasMultipleImages }"
        :style="`max-height:${maxHeight}px;`"
    >
        <div
            class="slides-wrapper"
            :style="{ transform: `translateX(${currentSliderTranslate}px)` }"
        >
            <div
                v-for="image in imagePaths"
                :key="image"
                class="slide"
            >
                <img
                    :src="image"
                    draggable="false"
                    class="slider-image"
                    :style="`max-height:${maxHeight}px;`"
                    onerror="this.style.opacity='0'"
                    @click="handleImageClick"
                >
            </div>
        </div>
        <div
            v-if="hasMultipleImages"
            class="slider-button previous"
            @click="previousSlide"
        >
            <icon-simple-arrow-thin :custom-color="buttonColor" />
        </div>
        <div
            v-if="hasMultipleImages"
            class="slider-button next"
            @click="nextSlide"
        >
            <icon-simple-arrow-thin :custom-color="buttonColor" />
        </div>
        <div
            v-if="hasMultipleImages"
            class="slider-image-counter f-14"
        >
            {{ (currentSlideIndex + 1) + '/' + imageCount }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { PropType } from 'vue';
    import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
    import IconSimpleArrowThin from '~/components/icons/IconSimpleArrowThin.vue';
    import { useAccountStore } from '~/stores/accountStore';
    import { useModalStore } from '~/stores/modalStore';

    const props = defineProps({
        imagePaths: {
            type: Array as PropType<string[]>,
            required: true,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 450
        },
        arrowNavigationAllowed: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const modalStore = useModalStore();
    const { buttonColor } = useAccountStore().state;
    const slider = ref<HTMLElement | null>(null);
    const currentSlideIndex = ref<number>(0);
    const currentSliderTranslate = ref<number>(0);
    const swipeStartX = ref<number>(0);
    const swipeEndX = ref<number>(0);

    const imageCount = computed(() => {
        return props.imagePaths.length;
    });

    const hasMultipleImages = computed(() => {
        return imageCount.value > 1;
    });

    const isFirstImage = computed(() => {
        return currentSlideIndex.value === 0;
    });

    const isLastImage = computed(() => {
        return currentSlideIndex.value === props.imagePaths.length - 1;
    });

    const previousSlide = () => {
        if (isFirstImage.value) {
            currentSlideIndex.value = props.imagePaths.length - 1;
        } else {
            currentSlideIndex.value--;
        }

        setCurrentTranslate();
    };

    const nextSlide = () => {
        if (isLastImage.value) {
            currentSlideIndex.value = 0;
        } else {
            currentSlideIndex.value++;
        }

        setCurrentTranslate();
    };

    const setCurrentTranslate = () => {
        if (!slider.value) {
            return;
        }

        currentSliderTranslate.value = currentSlideIndex.value * slider.value.clientWidth * -1;
    };

    const handleTouchStart = (e: any) => {
        swipeStartX.value = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: any) => {
        swipeEndX.value = e.changedTouches[0].clientX;

        if (swipeEndX.value + 30 < swipeStartX.value) {
            nextSlide();
        }

        if (swipeEndX.value - 30 > swipeStartX.value) {
            previousSlide();
        }
    };

    const handleImageClick = () => {
        // prevent opening modal on small screens
        if (window.innerWidth < 500) {
            return;
        }

        modalStore.setModalData(
            props.imagePaths[0],
            props.imagePaths,
            true,
        );
    };

    const handleKeyDown = (event: any) => {
        if (!props.arrowNavigationAllowed) {
            return;
        }

        if (event.key === 'ArrowLeft') {
            previousSlide();
        }

        if (event.key === 'ArrowRight') {
            nextSlide();
        }
    };

    onMounted(() => {
        // touch functionality allows users to swipe images on mobile devices
        slider.value?.addEventListener('touchstart', handleTouchStart);
        slider.value?.addEventListener('touchend', handleTouchEnd);
        document.addEventListener('keydown', handleKeyDown);
    });

    onBeforeUnmount(() => {
        slider.value?.removeEventListener('touchstart', handleTouchStart);
        slider.value?.removeEventListener('touchend', handleTouchEnd);
        document.removeEventListener('keydown', handleKeyDown);
    });
</script>

<style scoped lang="scss">
    .image-slider {
        position: relative;
        min-width: 100%;
        height: auto;
        background-color: #f7f7f7;
        overflow-x: hidden;

        &:hover {
            .slider-button {
                opacity: 1;
            }

            .slider-button.previous {
                left: 0;
            }

            .slider-button.next {
                right: 0;
            }
        }
    }

    .image-slider.auto-height {
        height: auto !important;
    }

    .slides-wrapper {
        display: flex;
        max-width: 750px;
        align-items: center;
        height: 100%;
        transition: all 0.6s ease;
    }

    .slide {
        display: flex;
        min-width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .slide.show {
        display: flex;
    }

    .slider-image {
        object-fit: contain;
        max-width: 100%;

        @media (max-width: 767px) {
            max-height: 370px;
        }
    }

    .slider-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #ffffff;
        padding: 1rem;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all 0.3s ease;

        svg {
            width: 1.2rem;
            height: 1.2rem;

            @media (max-width: 767px) {
                width: 1.05rem;
                height: 1.05rem;
            }
        }

        &:hover {
            filter: brightness(120%);
        }

        @media (max-width: 767px) {
            opacity: 1;
        }
    }

    .slider-button.previous {
        svg {
            transform: rotate(180deg);
        }
    }

    .slider-button.previous {
        left: -7px;
    }

    .slider-button.next {
        right: -7px;
    }

    .slider-image-counter {
        font-size: 0.8rem;
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: #ffffff;
        padding: 0.35rem 0.4rem;
        border-radius: 0.4rem;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    }
</style>
