<template>
    <div v-if="liteSidebarStore.hasLiteSidebar">
        <content-loader
            v-if="visible"
            class="hidden md:block"
            :primary-color="primaryColor"
        >
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="2"
                width="260"
            />
            <rect
                rx="3"
                ry="3"
                height="7"
                x="0"
                y="23"
                width="60"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="44"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="57"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="70"
                width="320"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="83"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="96"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="109"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="0"
                y="122"
                width="220"
            />
        </content-loader>
        <content-loader
            v-if="visible"
            class="md:hidden"
            :primary-color="primaryColor"
        >
            <rect
                rx="5"
                ry="5"
                height="12"
                x="0"
                y="2"
                width="400"
            />
            <rect
                rx="5"
                ry="5"
                height="10"
                x="0"
                y="34"
                width="120"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="64"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="82"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="100"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="118"
                width="260"
            />
        </content-loader>
    </div>
    <div v-else>
        <content-loader
            v-if="visible"
            class="hidden md:block"
            :primary-color="primaryColor"
        >
            <rect
                rx="3"
                ry="3"
                height="6"
                x="0"
                y="0"
                width="260"
            />
            <rect
                rx="5"
                ry="5"
                height="80"
                x="0"
                y="14"
                width="162"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="16"
                width="60"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="30"
                width="230"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="40"
                width="200"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="50"
                width="230"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="60"
                width="230"
            />
            <rect
                rx="3"
                ry="3"
                height="4"
                x="170"
                y="70"
                width="160"
            />
        </content-loader>
        <content-loader
            v-if="visible"
            class="md:hidden"
            :primary-color="primaryColor"
        >
            <rect
                rx="5"
                ry="5"
                height="12"
                x="0"
                y="2"
                width="400"
            />
            <rect
                rx="5"
                ry="5"
                height="10"
                x="0"
                y="34"
                width="120"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="64"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="82"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="100"
                width="400"
            />
            <rect
                rx="3"
                ry="3"
                height="8"
                x="0"
                y="118"
                width="260"
            />
        </content-loader>
    </div>
</template>

<script setup lang="ts">
    import { ContentLoader } from 'vue-content-loader';
    import { useLiteSidebarStore } from '~/stores/liteSidebarStore';

    defineProps({
        visible: {
            type: Boolean,
            required: false,
            default: true
        },
        primaryColor: {
            type: String,
            required: false,
            default: '#e5e5e5'
        }
    });

    const liteSidebarStore = useLiteSidebarStore();
</script>
